import "../assets/css/main.css";
import "../assets/css/dashboard.css";
import { BiCategory } from "react-icons/bi";
import {
  AiOutlineUser,
  AiOutlineCamera,
  AiOutlineSetting,
} from "react-icons/ai";
import { IoMdHelpCircleOutline } from "react-icons/io";
import { GoBook } from "react-icons/go";
import { FaTimes } from "react-icons/fa";
import { FiUsers, FiUserCheck } from "react-icons/fi";
import { BiMessageAltDetail, BiMoney, BiLogOut } from "react-icons/bi";
import { BsTelephonePlus, BsCalendarDate } from "react-icons/bs";
import { TbReport } from "react-icons/tb";
import { Link } from "react-router-dom";
import { useState, useEffect } from "react";
import useWindowDimensions from "../hooks/useWindowSize";
import { RxHamburgerMenu } from "react-icons/rx";


const adminList = {
  top: [
    {
      name: "Dashboard",
      link: "/admin",
      active: true,
      icon: <BiCategory />,
    },
    {
      name: "Pupil",
      link: "/admin/student",
      active: false,
      icon: <AiOutlineUser />,
    },
    {
      name: "Class ",
      link: "/admin/class",
      active: false,
      icon: <GoBook />,
    },
    {
      name: "Staff ",
      link: "/admin/staff",
      active: false,
      icon: <FiUsers />,
    },
    {
      name: "Message",
      link: "/admin/message",
      active: false,
      icon: <BiMessageAltDetail />,
    },
    {
      name: "Contact",
      link: "/admin/contact",
      active: false,
      icon: <BsTelephonePlus />,
    },
    {
      name: "Photos",
      link: "/admin/pv",
      active: false,
      icon: <AiOutlineCamera />,
    },
    {
      name: "Authorised Pickup",
      link: "/admin/authorizepickup",
      active: false,
      icon: <FiUserCheck />,
    },
    {
      name: "Event & Calendar",
      link: "/admin/events",
      active: false,
      icon: <BsCalendarDate />,
    },
    {
      name: "Auxiliary", // Billing. Make seperate for Auxiliary and Billing
      link: "/admin/billing",
      active: false,
      icon: <BiMoney />,
    },
    {
      name: "Archive", // Billing. Make seperate for Auxiliary and Billing
      link: "/admin/archive",
      active: false,
      icon: <BiMoney />,
    },
    {
      name: "Payment and Invoice",
      link: "/admin/account",
      active: false,
      icon: <FiUserCheck />,
    },
  ],
  bottom: [
    {
      name: "Settings",
      link: "/admin/setting",
      active: false,
      icon: <AiOutlineSetting />,
    },
    {
      name: "Log out",
      link: "/",
      active: false,
      icon: <BiLogOut />,
    },
  ],
};

const teacherListMobile = {
  top: [
    {
      name: "Dashboard",
      link: "/staff",
      active: true,
      icon: <BiCategory />,
    },
    {
      name: "Class Activities",
      link: "/staff/class/activities",
      active: false,
      icon: <GoBook />,
    },
    {
      name: "Pupils",
      link: "/staff/student/student_data",
      active: false,
      icon: <AiOutlineUser />,
    },
    {
      name: "Messages",
      link: "/staff/message",
      active: false,
      icon: <BiMessageAltDetail />,
    },

    {
      name: "Events",
      link: "/staff/events",
      active: false,
      icon: <BsCalendarDate />,
    },
    {
      name: "Photos",
      link: "/staff/pv",
      active: false,
      icon: <AiOutlineCamera />,
    },
    {
      name: "Authorised Pickup",
      link: "/staff/authorizepickup",
      active: false,
      icon: <FiUserCheck />,
    },
    {
      name: "Contact",
      link: "/staff/contact",
      active: false,
      icon: <BsTelephonePlus />,
    },
    // {
    //   name: "Report Sheet",
    //   link: "/staff/generate-report-sheet",
    //   active: false,
    //   icon: <TbReport />,
    // },
  ],

  bottom: [
    {
      name: "Change Password",
      link: "/staff/changePassword",
      active: false,
      icon: <AiOutlineSetting />,
    },
    {
      name: "Log out",
      link: "/",
      active: false,
      icon: <BiLogOut />,
    },
  ],
};

export const AdminNav = () => {
  const cachedActive = sessionStorage.getItem("activeNavItem");
  const [isActive, setIsActive] = useState(Number(cachedActive) ?? 0);
  const [isOpen, setIsOpen] = useState(false);
  const { width: windowWidth } = useWindowDimensions();

  const isMobileScreen = windowWidth < 440;
  const user = JSON.parse(sessionStorage.getItem("user") as string);

  useEffect(() => {
    handleItemSelect(Number(cachedActive));

  }, []);

  const togH = () => {
    setIsOpen(!isOpen);
  };
  const handleItemSelect = (idx: number) => {
    setIsActive(idx);
    setIsOpen(false)
    sessionStorage.setItem("activeNavItem", idx.toString());
    // console.log(windowWidth);
  };

  return (
    <div className="sideTop">
      <div className={`${isOpen ? "activeNav overflow-y-scroll no-scrollbar" : "sideNav"}`}>
        <div onClick={togH} className="toggleBtnns !fixed !top-[20px] !left-[12px]">
          <RxHamburgerMenu className="toggleBtnnsIcon" />
        </div>
        <div className="logo">
          <img src="/img/logo.svg" alt="logo here" className="laptopNav" />
          {/* <FontAwesomeIcon icon={"bars"} color="white" className="mobileNav border-4 h-40 w-40 z-10" /> */}
          <div onClick={togH} className="jio">
            <FaTimes className="jiso" />
          </div>
        </div>
        <ul className='listTop pb-4 overflow-y-scroll no-scrollbar'>
          {(user?.role === "teacher"
            ? teacherListMobile
            : user?.role === "super_admin"
            ? adminList
            : teacherListMobile
          ).top.map((item, index) => {
            return (
              <li
                key={index}
                onClick={() => handleItemSelect(index)}
                className={`${
                  isActive === index
                    ? "bg-white w-full text-[#012063]"
                    : "text-white  w-full"
                }`}
              >
                <Link to={item.link}>
                  {item.icon}
                  <p>{item.name}</p>
                </Link>
              </li>
            );
          })}
          <div className="listBottom">

            {user?.role === "super_admin" &&
            (<li onClick={() => handleItemSelect(18)}
            className={`${
              isActive === 18
                ? "bg-white w-full text-[#012063]"
                : "text-white  w-full"
            }`}
             style={{width: "100%"}}>
              <Link to="/admin/enrollment">
                <IoMdHelpCircleOutline />
                <p>Enrollment Support</p>
              </Link>
            </li>)
            }

            <li onClick={() => handleItemSelect(19)}
            className={`${
              isActive === 19
                ? "bg-white w-full text-[#012063]"
                : "text-white  w-full"
            }`}
             style={{width: "100%"}}>
              <Link to="/admin/setting">
                <AiOutlineSetting />
                <p>Setting</p>
              </Link>
            </li>
            <li onClick={() => handleItemSelect(20)}
            className={`${
              isActive === 20
                ? "bg-white w-full text-[#012063]"
                : "text-white  w-full"
            }`}
             style={{width: "100%"}}>
              <Link onClick={() => window.sessionStorage.clear()} to="/">
                <BiLogOut />
                <p>Log Out</p>
              </Link>
            </li>
          </div>
        </ul>
      </div>
    </div>
  );
};
