import axios from "axios";
import { useEffect, useState } from "react";
import { FaClock } from "react-icons/fa";
import { GrDocumentPdf } from "react-icons/gr";
import { useSelector } from "react-redux";
import { RootState } from "../app/store";
import BackButton from "../components/backButton";
import { TopAdminNav } from "../layouts/TopAdminNav";
import { globalToken } from "./AdminHome";
import Swal from "sweetalert2";
import { FiTrash2 } from "react-icons/fi";
import { defaultUrl } from "../features/actions";
import { useParams } from "react-router-dom";
import { toast } from "react-hot-toast";
import { makeDeleteRequest, makeFetchRequest } from "../utilities/common";

interface FeedType {
  id: string;
  description: string;
  activity_type: string;
  data: any;
  doc: string,
  image: string[];
  video: {
    props: {
      src:string
    }
  }
  activity_time: string;
}

export const StudentActivityFeed = () => {

  const [activities, setActivities] = useState<FeedType[]>([]);

  let { pupil_id } = useParams();
  useEffect(() => {
    fetchStudentActivities(pupil_id as string);
  }, []);

  async function fetchStudentActivities(studentID: string) {
    // console.log({link: `${defaultUrl}/activity_feed?student_id=${studentID}`, globalToken})

    try {
      toast.loading("Fetching student activity...");
      const result = await makeFetchRequest(`${defaultUrl}/activity_feed?student_id=${studentID}&per_page=100`)
      let feed: any[] = result;


      feed.forEach((item, i) => {
        if (item.data) {
          item.data = Object.entries(item.data);
        }
        if (item.image && item.image[0] !== "null") {
          item.image = (
            <img src={item.image} alt="activity" className="max-w-[320px]" />
          );
        } else {
          item.image = null;
        }
        if (item.video !== "null") {
          item.video = <video src={item.video} className="max-w-[320px]" />;
        } else {
          item.video = null;
        }

        const date = new Date(item.activity_time);
        const formattedDate = `${date.getFullYear()}-${(date.getMonth() + 1)
          .toString()
          .padStart(2, "0")}-${date
            .getDate()
            .toString()
            .padStart(2, "0")} ${date
              .getHours()
              .toString()
              .padStart(2, "0")}:${date.getMinutes().toString().padStart(2, "0")}`;
        item.activity_time = formattedDate;
      });

      setActivities(result);

      toast.remove();

    } catch (err: any) {
      // console.log({ error: err.response || err.message });
      toast.remove();
      toast.error("Fetch unsuccessful");
    }
  }

  async function deleteActivity(activity: any) {
    Swal.fire({
      icon: "warning",
      title: "Delete Activity",
      text: "Are you sure you want to delete this activity",
      showCancelButton: true,
      confirmButtonColor: "red",
      confirmButtonText: "Yes, Delete",
      cancelButtonColor: "navy",
      cancelButtonText: "No, Cancel",
    }).then((response) => {
      // console.log(response);
      if (response.isConfirmed) {
        makeDeleteRequest(`${defaultUrl}/activity_feed/${activity.id}`)
        .then(() => fetchStudentActivities(pupil_id as string));
      }
    });
  }

  function colorFactory(id: string) {
    if (id.toLowerCase() === "login") {
      return { color: "#1768A9", backgroundColor: "#CEE9FF" };
    }
    if (id.toLowerCase() === "health") {
      return { color: "#5E9F0D", backgroundColor: "#F3FCE8" };
    }
    if (id.toLowerCase() === "temperature") {
      return { color: "#227DB2", backgroundColor: "#E8F6FF" };
    }
    if (id.toLowerCase() === "food") {
      return { color: "#D3311E", backgroundColor: "#FCD9D5" };
    }
    if (id.toLowerCase() === "liquids") {
      return { color: "#F127A8", backgroundColor: "#F8EAF3" };
    }
    if (id.toLowerCase() === "mood") {
      return { color: "#29376D", backgroundColor: "#DBECFD" };
    }
    if (id.toLowerCase() === "sleeping time") {
      return { color: "#234CA8", backgroundColor: "#EEEAF8" };
    }
    if (id.toLowerCase() === "toilet") {
      return { color: "#025166", backgroundColor: "#E7FAFF" };
    }
    if (id.toLowerCase() === "note") {
      return { color: "#CF235A", backgroundColor: "#FFD6E3" };
    }
    if (id.toLowerCase() === "sport") {
      return { color: "#195AC7", backgroundColor: "#F3F9FF" };
    }
    if (id.toLowerCase() === "supplies") {
      return { color: "#195AC7", backgroundColor: "#E3E9F3" };
    }
    if (id.toLowerCase() === "logout") {
      return { color: "#583F05", backgroundColor: "#FEECC1" };
    }
    if (id.toLowerCase() === "school activity") {
      return { color: "#234CA8", backgroundColor: "#EEEAF8" };
    }

    return { color: "#1768A9", backgroundColor: "#CEE9FF" };
  }

  const colors = [
    "#CEE9FF",
    "#F3FCE8",
    "#E8F6FF",
    "#FCD9D5",
    "#F8EAF3",
    "#DBECFD",
    "#EEEAF8",
    "#E7FAFF",
    "#FFD6E3",
    "#FFD6E3",
    "#E3E9F3",
    "#E3E9F3",
    "#EEEAF8",
  ];

  const user = JSON.parse(sessionStorage.getItem("user") as string);
  // console.log("user", user);

  return (
    <div>
      <section className="w-full flex items-center">
        <BackButton />
        <TopAdminNav name="Jone Doe" title="Pupil Activity Timeline" />
      </section>
      <section className="max-h-[90vh] overflow-auto register-scroll">
        <div>
          {activities?.length ? (
            activities.map((item, index) => {

              return (
                <div
                  key={index}
                  style={{
                    background: colorFactory(item.activity_type)
                      .backgroundColor,
                  }}
                  className={` rounded border border-gray-200 my-4 p-1 md:p-2 md:px-4 text-[14px] md:text-[16px]`}
                >
                  <div>{/* <img src={item?.image} alt="" /> */}</div>
                  <div>
                    <p
                      style={{ color: colorFactory(item.activity_type).color }}
                      className={`capitalize font-semibold text-[15px] md:text-[18px]`}
                    >
                      {item.activity_type}
                    </p>
                    {item.data?.map((entry: any[], idx: number) => {
                      if (entry[0] === "items_needed") {
                        return (
                          <p className="" key={idx}>
                            <span className="capitalize text-[14px] text-gray-500">
                              {entry[0]}:{" "}
                            </span>
                            {Array.isArray(entry[1])
                              ? entry[1].map((item: string, i: number) => (
                                <span>{`${item}${i === entry[1].length - 1 ? "" : ", "
                                  } `}</span>
                              ))
                              : entry[1]}
                          </p>
                        );
                      }
                      return (
                        <p className="" key={idx}>
                          <span className="capitalize text-[14px] text-gray-500">
                            {entry[0]}:{" "}
                          </span>
                          {entry[1]}
                        </p>
                      );
                    })}
                    {item.description && (
                      <p className="max-h-20 max-w-full register-scroll text-[13px] md:text-[15px]">
                        <span className="text-[14px] text-gray-500">
                          Description:{" "}
                        </span>
                        {item.description}
                      </p>
                    )}

                    {item.image && <div className="">{item.image}</div>}

                    {/* IF A VIDEO WAS UPLOADED */}

                    {(item.video && item?.video?.props?.src) &&
                      <div className="">
                        <video controls width="640" height="360">
                          <source src={item?.video?.props?.src} type="video/mp4" />
                          Your browser does not support the video tag.
                        </video>

                      </div>}
                      {/* IF A PDF WAS UPLOADED */}
                    {item.doc &&
                      <div className="my-4">
                        <a href={item.doc}
                          download>
                          <GrDocumentPdf className="text-3xl sm:text-6xl" />
                          Click to download PDF</a>
                      </div>}

                    <p className="w-full flex items-center justify-end text-[12px] mt-2">
                      <span className="mr-1 text-[13px] text-blue-900">
                        <FaClock />
                      </span>
                      {item.activity_time}
                    </p>
                    <div className="flex justify-end">
                      <button

                        onClick={() => deleteActivity(item)}
                        className={`w-40 rounded p-4 py-2 bg-red-600 text-center text-white text-[14px] my-4 flex items-center hover:bg-red-400  ${user.role === "super_admin"
                            ? "cursor-pointer"
                            : "cursor-not-allowed"
                          }`}
                        disabled={user.role === "super_admin" ? false : true}
                      >
                        <span className="mr-2">
                          <FiTrash2 />
                        </span>
                        Delete Activity
                      </button>
                    </div>
                  </div>
                </div>
              );
            })
          ) : (
            <div className="animate-pulse rounded border-2 h-40 w-full flex items-center justify-center text-gray-400">
              <p>No Activities for this child ...yet</p>
            </div>
          )}
        </div>
      </section>
    </div>
  );
};
