import axios from 'axios';
import { useEffect, useState } from 'react';
import { FaClock } from 'react-icons/fa';
import { useSelector } from 'react-redux';
import { RootState } from '../app/store';
import BackButton from '../components/backButton';
import { TopAdminNav } from '../layouts/TopAdminNav';
import Swal from 'sweetalert2';
import { FiTrash2 } from 'react-icons/fi';
import { defaultUrl } from '../features/actions';
import { useParams } from 'react-router-dom';
import { toast } from 'react-hot-toast';
import { GrDocumentPdf } from "react-icons/gr";

interface FeedType {
    id: string;
    description: string;
    activity_type: string;
    data: any;
    doc: string,
    image: string[];
    video: {
        props: {
            src: string
        }
    }
    activity_time: string;
}

export const StaffStudentFeed = () => {
    const token = sessionStorage.getItem('token') as string;
    const { selectedStudent: studentID } = useSelector((state: RootState) => state.student);
    const [date, setDate] = useState()
    const [activities, setActivities] = useState<FeedType[]>([]);
    let { pupil_id } = useParams();
    useEffect(() => {
        fetchStudentActivities(pupil_id as string);
    }, [])

    async function fetchStudentActivities(pupil_id: string) {
        // console.log({link: `${defaultUrl}/activity_feed?student_id=${studentID}`, globalToken})

        try {
            toast.remove();
            toast.loading("Fetching student activity...");
            const result = await axios.get(`${defaultUrl}/activity_feed?student_id=${pupil_id}&per_page=100`, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            });
            let feed: any[] = result.data.data;
            feed.forEach((item, i) => {
                if (item.data) {
                    item.data = Object.entries(item.data);
                }
                if (item.image && item.image[0] !== "null") {
                    item.image = <img src={item.image} alt="activity" className="max-w-[320px]" />
                } else { item.image = null; }
                if (item.video !== "null") {
                    item.video = <video src={item.video} className="max-w-[320px]" />
                } else { item.video = null; }

                const date = new Date(item.activity_time);
                const formattedDate = `${date.getFullYear()}-${(date.getMonth() + 1).toString().padStart(2, '0')}-${date.getDate().toString().padStart(2, '0')} ${date.getHours().toString().padStart(2, '0')}:${date.getMinutes().toString().padStart(2, '0')}`;
                item.activity_time = formattedDate;

            });
            setActivities(result.data.data);

            // console.log({ result: result.data.data})

            toast.remove();
            toast.success("Fetch completed");
        }
        catch (err: any) {
            console.log({ error: err.response || err.message })
            toast.remove();
            toast.error("Fetch unsuccessful");
        }
    }

    function colorFactory(id: string) {
        if (id.toLowerCase() === "login") {

            return { color: "#1768A9", backgroundColor: "#CEE9FF" }
        }
        if (id.toLowerCase() === "health") {

            return { color: "#5E9F0D", backgroundColor: "#F3FCE8" }
        }
        if (id.toLowerCase() === "temperature") {

            return { color: "#227DB2", backgroundColor: "#E8F6FF" }
        }
        if (id.toLowerCase() === "food") {

            return { color: "#D3311E", backgroundColor: "#FCD9D5" }
        }
        if (id.toLowerCase() === "liquids") {

            return { color: "#F127A8", backgroundColor: "#F8EAF3" }
        }
        if (id.toLowerCase() === "mood") {

            return { color: "#29376D", backgroundColor: "#DBECFD" }
        }
        if (id.toLowerCase() === "sleeping time") {

            return { color: "#234CA8", backgroundColor: "#EEEAF8" }
        }
        if (id.toLowerCase() === "toilet") {

            return { color: "#025166", backgroundColor: "#E7FAFF" }
        }
        if (id.toLowerCase() === "note") {

            return { color: "#CF235A", backgroundColor: "#FFD6E3" }
        }
        if (id.toLowerCase() === "sport") {

            return { color: "#195AC7", backgroundColor: "#F3F9FF" }
        }
        if (id.toLowerCase() === "supplies") {

            return { color: "#195AC7", backgroundColor: "#E3E9F3" }
        }
        if (id.toLowerCase() === "logout") {

            return { color: "#583F05", backgroundColor: "#FEECC1" }
        }
        if (id.toLowerCase() === "school activity") {

            return { color: "#234CA8", backgroundColor: "#EEEAF8" }
        }

        return { color: "#1768A9", backgroundColor: "#CEE9FF" };
    }

    const colors = ['#CEE9FF', '#F3FCE8', '#E8F6FF', '#FCD9D5', "#F8EAF3", '#DBECFD', '#EEEAF8', '#E7FAFF', '#FFD6E3', '#FFD6E3', '#E3E9F3', '#E3E9F3', '#EEEAF8']

    return (
        <div>
            <section className="w-full flex items-center">
                <BackButton />
                <TopAdminNav name="Jone Doe" title="Pupil Activity Timeline" />
            </section>
            <section className='max-h-[90vh] overflow-auto register-scroll'>
                <div>
                    {
                        activities?.length ?
                            activities.map((item, index) => {
                                // console.log({item: Object.entries(item.data)})
                                return (
                                    <div key={index} style={{ background: colorFactory(item.activity_type).backgroundColor }} className={` rounded border border-gray-200 my-4 p-1 md:p-2 md:px-4 text-[14px] md:text-[16px]`}>
                                        <div>
                                            {/* <img src={item?.image} alt="" /> */}
                                        </div>
                                        <div>
                                            <p style={{ color: colorFactory(item.activity_type).color }} className={`capitalize font-semibold text-[15px] md:text-[18px]`}>{item.activity_type}</p>
                                            {
                                                item.data?.map((entry: any[], idx: number) => {
                                                    if (entry[0] === 'items_needed') {
                                                        return <p key={idx} className=''><span className='capitalize text-[14px] text-gray-500'>{entry[0]}: </span>{Array.isArray(entry[1]) ? entry[1].map((item: string, i: number) => <span>{`${item}${i === entry[1].length - 1 ? "" : ", "} `}</span>) : entry[1]}</p>
                                                    }
                                                    return <p key={idx} className=''><span className='capitalize text-[14px] text-gray-500'>{entry[0]}: </span>{entry[1]}</p>
                                                })
                                            }
                                            {item.description && <p className='max-h-20 max-w-full overflow-auto register-scroll text-[13px] md:text-[15px]'><span className='text-[14px] text-gray-500'>Description: </span>{item.description}</p>}

                                            {item.image && <div className="">{item.image}</div>}

                                            {/* IF A VIDEO WAS UPLOADED */}

                                            {(item.video && item?.video?.props?.src) &&
                                                <div className="">
                                                    <video controls width="640" height="360">
                                                        <source src={item?.video?.props?.src} type="video/mp4" />
                                                        Your browser does not support the video tag.
                                                    </video>

                                                </div>}
                                            {/* IF A PDF WAS UPLOADED */}
                                            {item.doc &&
                                                <div className="my-4">
                                                    <a href={item.doc}
                                                        download>
                                                        <GrDocumentPdf className="text-3xl sm:text-6xl" />
                                                        Click to download PDF</a>
                                                </div>}
                                        </div>
                                    </div>
                                )
                            })
                            :
                            <div className='animate-pulse rounded border-2 h-40 w-full flex items-center justify-center text-gray-400'>
                                <p>No Activities for this child ...yet</p>
                            </div>
                    }
                </div>
            </section>
        </div>
    )
}
