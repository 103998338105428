import React, { useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import { reportCardInfo } from '../../../features/sharedStateSlices/shareState';
import { BsFileEarmarkArrowDownFill } from 'react-icons/bs';


const ReportCardDisplay = ({ ownerData }: any) => {
    const reportRef = useRef<HTMLDivElement>(null);

    const reportData = useSelector(reportCardInfo)
    
    const rendered = reportData?.rows?.results && reportData?.rows?.results.map((sheetArray: any, index: number) => {
        return (
            <div key={index} className="overflow-x-auto my-5">
                <table>

                    <tbody>
                        {/* HEADING COLUMNS */}
                        <tr>
                            {sheetArray[0]?.map((item: any, idx: number) => {
                                return (<th key={idx} className="border-2 p-2 pr-4 text-left">{item}</th>)
                            })}
                        </tr>

                        {/* EXCEL FILE ROW 2  */}

                        {sheetArray?.slice(1)?.map((item1: any, idx: number) => {
                            return (
                                <tr key={idx}>
                                    {item1 && item1.map((item2: any, idx2: any) => {
                                        return (<th key={idx2} className="border-2 p-2 pr-4 text-left">{item2}</th>)
                                    })}
                                </tr>)
                        })}
                    </tbody>

                </table>
            </div>

        )
    })



    const handleDownloadPDF = async () => {
        const element = reportRef.current;
        if (!element) return;

        try {
            const html2canvas = (await import('html2canvas')).default;
            const jsPDF = (await import('jspdf')).default;

            // Calculate dimensions
            const elementHeight = element.offsetHeight;
            const elementWidth = element.offsetWidth;
            
            // Create the canvas with proper dimensions
            const canvas = await html2canvas(element, {
                height: elementHeight,
                width: elementWidth,
                scale: 2,
                useCORS: true,
                logging: false,
                windowHeight: elementHeight,
                // Ensure full content capture
                onclone: (document, element) => {
                    // Make any hidden elements visible in the clone
                    element.style.height = 'auto';
                    element.style.overflow = 'visible';
                }
            });

            // Calculate dimensions for PDF
            const imgWidth = 210; // A4 width in mm
            const pageHeight = 297; // A4 height in mm
            const imgHeight = (canvas.height * imgWidth) / canvas.width;
            
            // Create PDF with proper orientation
            const pdf = new jsPDF('p', 'mm', 'a4');
            // let position = 0;

            // Add image to PDF
            if (imgHeight <= pageHeight) {
                // If content fits in one page
                pdf.addImage(canvas.toDataURL('image/jpeg', 1.0), 
                           'JPEG', 0, 0, imgWidth, imgHeight);
            } else {
                // If content needs multiple pages
                let heightLeft = imgHeight;
                let currentPosition = 0;

                while (heightLeft > 0) {
                    pdf.addImage(
                        canvas.toDataURL('image/jpeg', 1.0),
                        'JPEG',
                        0,
                        currentPosition,
                        imgWidth,
                        imgHeight
                    );
                    heightLeft -= pageHeight;
                    currentPosition -= pageHeight;
                    if (heightLeft > 0) {
                        pdf.addPage();
                    }
                }
            }

            // Save the PDF
            const fileName = ownerData?.child_name?.toUpperCase() || 'report_card';
            pdf.save(`${fileName}.pdf`);

        } catch (error) {
            console.error('Error generating PDF:', error);
        }
    };



    useEffect(() => {

    }, [])

    return (
        <div className="space-y-4 px-3 my-10">
            <div className="mx-auto p-4" id="report-card" ref={reportRef}>
                <div className='flex justify-between'>
                    <div className='w-[60%]'>
                {/* <img src={} alt="school logo" className='' /> */}
            </div>

            <div className='h-[150px] w-{150px] rounded-xl'>
                <img src={ownerData?.profile_picture} 
                alt="school logo" 
                className='h-full w-full' />
            </div>
                </div>

                <div className="text-center space-y-4 my-8">
                    <div className="text-2xl font-bold">{reportData?.title?.toUpperCase()}</div>
                    <div className="my-4 flex justify-between">
                    <div className='text-left'>
                    <div>
                            <p><span className="font-bold mx-2">PUPIL'S NAME:
                                </span><b className='text-lg'>{ownerData?.child_name?.toUpperCase()}</b></p>
                            <p><span className="font-bold mx-2">CLASS:
                                </span>{ownerData?.classroom?.toUpperCase()}</p>
                        </div>
                    <div>
                            <p><span className="font-bold mx-2">DATE OF BIRTH:</span>
                            {ownerData?.date_of_birth}
                            </p>
                            <p><span className="font-bold mx-2">TEACHER:
                                </span>{ownerData?.teacher_name?.toUpperCase()}</p>
                        </div>
                    </div>

                    <div className='text-left flex flex-col justify-center'>
                        {reportData?.averageScore > 0 &&
                        (
                        <div>
                            <p><span className="font-bold mx-2">AVERAGE SCORE:</span>{parseFloat(reportData?.averageScore)}</p>
                        </div>
                        )}
                        <div>
                            <p><b className="mx-2">ATTENDANCE:</b>
                                <b>{parseFloat(reportData?.presentDays)}</b> out of
                                <b className='ml-2'>{parseFloat(reportData?.schoolDays)}</b></p>
                        </div>
                    </div>

                    </div>
                </div>

                <div className="space-y-8">
                    {/* Academic Performance Table */}
                    <div className="overflow-x-auto">
                        {rendered}
                    </div>

                    {/* Grading Key */}
                    <div className="text-sm">
                        <h3 className="font-bold mb-2">KEYS TO GRADING</h3>
                    {reportData?.rows?.attitude.length > 0 ?
                    (<p>0-39.9: F- FAIL |  40.0-49.9: E - POOR |
                        50.0-59.9: D - AVERAGE | 60.0-69.9: C – GOOD |
                        70.0 – 79.9: B– VERY GOOD | 80.0 – 89.9: A EXCELLENT |
                         90 – 100: A* DISTINCTION</p>)
                    :
                    (<p>M – Mastered, S – Satisfactory, W – Working on skill, NM – Not Mastered.</p>)
                        }
                    </div>
                    


                    {/* Behaviour and Skills */}
                    
                        {reportData?.rows?.attitude.length > 0 && (
                            <div>
                            <div className="overflow-x-auto">
                                <table>

                                    <tbody>
                                        {/* HEADING COLUMNS */}
                                        <tr>
                                            {reportData?.rows?.attitude[0]?.map((item: any, idx: any) => {
                                                return (<th key={idx} className="border-2 p-2">{item}</th>)
                                            })}
                                        </tr>

                                        {/* EXCEL FILE ROW 2  */}
                                        {reportData?.rows?.attitude?.slice(1)?.map((item1: any, idx: any) => {
                                            return (
                                                <tr key={idx}>
                                                    {item1 && item1.map((item2: any, idx2: any) => {
                                                        return (<th key={idx2} className="border-2 p-2">{item2}</th>)
                                                    })}
                                                </tr>)
                                        })}
                                    </tbody>

                                </table>
                            </div>
                            <p className="text-sm mt-2">LEGEND: 5 - EXCEPTIONAL | 4 - VERY GOOD | 3 - GOOD | 2 - SATISFACTORY | 1 - POOR</p>
                            </div>
                        )}


                    {/* Comments Section */}
                    <div className="space-y-4">
                        <div className='flex'>
                            <h3 className="font-bold mr-2">SCHOOL RESUMES:</h3>
                            <p className='font-medium'>{reportData?.resumeDate}</p>
                        </div>

                        <div>
                            <h3 className="font-bold">Class Teacher's General Comment:</h3>
                            <p className="text-sm">{reportData?.teacherComment}</p>
                        </div>

                        <div>
                            <h3 className="font-bold">Head Teacher's Comment:</h3>
                            <p className='text-sm'>{reportData?.headTeacherComment}</p>
                        </div>

                        <div className='flex'>
                            <h3 className="font-bold mr-2">Head Teacher's Signature:</h3>
                            <div className='h-[30px] w-{30px]'>
                                <img src={ownerData?.profile_picture} 
                                alt="school logo" 
                                className='h-full w-full' />
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* Download Buttons */}
            <div className="max-w-4xl mx-auto flex justify-end gap-4">
        <button 
        onClick={handleDownloadPDF} 
        className="bg-black text-white px-4 py-2.5 rounded-lg flex items-center gap-2">
          <BsFileEarmarkArrowDownFill  className="w-4 h-4"/>
          Download as PDF
        </button>
      </div>
        </div>
    );
};

export default ReportCardDisplay;